import React, {useState} from "react";
import {Row, Col, Container} from "react-bootstrap";
import {useAsyncSetState} from "use-async-setstate";
import Formsy from "formsy-react";

import {debounce} from "../utils/index";
import {
  formatLocation,
  searchServiceAvailabilityQuery,
  searchServiceAvailabilityResult,
} from "../logic/address";

import MapMarkerAltIcon from "../../images/icons/map-marker-alt.svg";
import TimesWhiteImage from "../../images/icons/times-white.svg";
import {setFormData} from "../manager/form";
import {client} from "../main";


export default function AddressAutoComplete(props) {
  // const [selectedAddress, setSelectedAddress] = useAsyncSetState(null);
  const [locations, setLocations] = useState([]);
  const [addressError, setAddressError] = useAsyncSetState(false);
  const [selectedLocation, setSelectedLocation] = useAsyncSetState(null);

  const {highlight, home, init, inline, showLocator} = props;
  const rowClassName = `
    vw-address-locator 
    ${inline ? "inline" : ""} 
    ${highlight ? "highlight" : ""} 
    ${showLocator ? "show-address-locator" : "hide-address-locator"} 
    ${init ? "vw-hide" : ""} 
    magictime-300ms
  `;

  // eslint-disable-next-line consistent-return
  const handleValidSubmit = async () => {
    if (!selectedLocation) {
      return setAddressError(true);
    }
    setFormData(selectedLocation);
    const {locationId} = selectedLocation;
    window.location.href = `/register?locationId=${locationId}`;
  };

  const handleAddressChange = debounce(async (address) => {
    const response = await client.query({
      fetchPolicy: "no-cache",
      query: searchServiceAvailabilityQuery,
      variables: {address},
    });
    const result = searchServiceAvailabilityResult(response);

    if (!result.length) {
      return setAddressError(true);
    }

    setAddressError(false);
    return setLocations(result);
  }, 250);

  const handleLocationSelect = (value) => {
    setSelectedLocation(value);
  };


  return (
    <Formsy>
      <div className={rowClassName}>
        <Container fluid className="no-gutters">
          <Row>
            <Col className="vw-al-header">
              {"To get started, check your address"}
            </Col>
          </Row>
          <Row
            className={`${
              home ? "" : "justify-content-center"
            } flex-align-center no-gutters`}
          >
            <Col xs={12} md={12} lg={9} className="mb-1 mb-md-0">
              <div className={`vw-field ${home ? "highlight" : ""} vw-street`}>
                <div
                  className={`vw-placeholder ${
                    selectedLocation !== null ? "hasValue" : ""
                  }`}
                >
                  {"Street Address"}
                </div>
                {selectedLocation ? (
                  <div
                    className="vw-al-selected-address-bg"
                    style={{backgroundImage: `url(${MapMarkerAltIcon})`}}
                  >
                    <div
                      className="vw-al-selected-address-label"
                      onClick={() => setSelectedLocation(null)}
                      style={{backgroundImage: `url(${TimesWhiteImage})`}}
                    >
                      {formatLocation(selectedLocation)}
                    </div>
                  </div>
                ) : (
                  <input
                    name="address"
                    type="text"
                    autoComplete="off"
                    onChange={async (e) =>
                      await handleAddressChange(e.target.value)
                    }
                    className={`vw-al-input-field ${
                      addressError ? "border-red" : ""
                    }`}
                  />
                )}
                {!selectedLocation && locations.length > 0 && (
                  <div className="vw-al-locations">
                    {locations.map(location => (
                      <div
                        key={`address-${location.id}`}
                        className="vw-al-location"
                        style={{backgroundImage: `url(${MapMarkerAltIcon})`}}
                        onClick={() => handleLocationSelect(location)}
                      >
                        {formatLocation(location)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={12} lg={"auto"} className="mt-md-1 mt-lg-0 ml-lg-1">
              <div className="vw-field">
                <button
                  onClick={() => handleValidSubmit()}
                  className={"check-el-btn btn vw-button orange w-100 w-md-unset"}
                >
                  {"Check Eligibility"}
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Formsy>
  );
}
